import React from "react"

const Footer = () => {
  return (
    <section id="footer">
      <div className="footer">
        <p>Built with ❤️</p>
      </div>
    </section>
  )
}

export default Footer
